import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { Toast } from 'vant';
import Vue from 'vue';
import { Swipe, SwipeItem } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Grid, GridItem } from 'vant';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { List } from 'vant';
import { Slider } from 'vant';
import 'vant/es/empty/style';
import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    }
  },
  data() {
    return {
      banner: [],
      goods: [],
      lang_list: [],
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      news: [],
      sort_line: 0,
      loading: false,
      finished: false,
      is_jia: false,
      page: 1,
      value: [0, 100000],
      shuxing: [],
      select_v: 0
    };
  },
  created: function () {
    let _this = this;
    // _this.get_imdex_data();
    let sort_line = local.readInfo('sort_line1') == undefined || local.readInfo('sort_line1') == null ? 0 : local.readInfo('sort_line1');
    this.sort_line = sort_line;
  },
  methods: {
    onClickLeft() {
      api.chat(0);
    },
    back() {
      window.history.back();
    },
    sort: function (type) {
      this.sort_line = type;
      local.saveInfo('sort_line1', type);
    },
    onChange: function () {
      this.onload(true);
    },
    onload: function (name = false) {
      if (this.is_jia) {
        return false;
      }
      this.is_jia = true;
      if (name) {
        this.goods = [];
        this.page = 1;
        this.finished = false;
        this.is_has = 1;
      }
      api.all('/api/index/news', {
        page: this.page,
        id: this.$route.query.id,
        price: this.value,
        sort: this.select_v
      }, (err, data) => {
        if (!err && data.code === 1) {
          this.loading = false;
          this.is_jia = false;
          let oldArr = this.goods;
          let newArr = data.data.goods;
          this.goods = oldArr.concat(newArr);
          //this.banner = data.data.banner;
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.cate = data.data.cate;
          this.user = data.data.user;

          //   this.shuxing =  data.data.shuxing

          if (data.data.count <= this.page * 20) {
            this.finished = true;
          }
          this.page++;
        } else {
          //console.log(data);
          //  if(data&&'msg' in data){
          //    Toast(data.msg)
          //  }
          //this.block = false
        }
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      if (this.logining) {
        return;
      }
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.get_imdex_data(true);
    },
    detail: function (id1) {
      let _this = this;
      _this.$router.push({
        name: 'ac_detail',
        query: {
          id: id1
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部  
      });
    },
    shou: function (id = 0) {
      api.shou(id);
    }
  }
};